.site-header {
	background-color: $black;
	margin-bottom: 2rem;

	#site-logo {
		display: flex;
		align-items: center;

		svg {
			width: 250px;
			height: auto;

			path {
				fill: $white;
			}
		}
	}

	.menu-icon {
		&::after {
			background: $primary-color;
		    box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
		}
	}
}

.off-canvas {
	padding: 1rem;
}