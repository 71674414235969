@for $i from 1 through 6 {
  h#{$i} {
    // letter-spacing: -1%;
  }

  .has-h-#{$i}-font-size {
  	@extend h#{$i};
  }
}

h3, h4, h5, h6 {
	font-family: $body-font-family;
	font-weight: $global-weight-bold;
}

.spacer {
	display: block;
	width: 100%;
	height: 3rem;

	&.large {
		height: 4rem;
	}

	&.small {
		height: 1.5rem;
	}

	@include breakpoint(large) {
		height: 4rem;

		&.large {
			height: 8rem;
		}

		&.small {
			height: 2rem;
		}
	}
}

hr.full-width {
	max-width: none;
}

.font-semibold {
	font-weight: 500;
}

.styled-with-arrow {
	text-transform: uppercase;
	position: relative;
	font-weight: $global-weight-bold;
	display: inline-block;
	padding-bottom: 8px;

	i, .svg-inline--fa {
		padding-left: 8px;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		border-bottom: 2px solid $anchor-color;
		transition: all 0.25s ease-out;
	}

	&:hover {
		&:after {
			bottom: 2px;
			border-bottom: 2px solid $anchor-color-hover;
		}
	}
}