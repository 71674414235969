.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;

  .site-header {
    flex: 0 0 auto;
  }

  .page-content {
    flex: 1 1 0;
  }

  .site-footer {
    flex: 0 0 auto;
  }
}

/** === Base Color Palatte === */
@each $color, $code in $foundation-palette {

  .has-#{$color}-background-color {
      background-color: $code;
  }

  .has-#{$color}-color {
      color: $code;
  }
}

.radius {
  border-radius: $global-radius;
}

section#connect {
  margin-top: 4rem;
}

video {
  width: 100%;
  height: auto;
  border-radius: $global-radius;
}

.tooltip {
  text-align: center;
}

.animate {
  transition: all 0.5s ease;

  &.speed-500 {
    transition: all 0.5s ease;
  }

  &.speed-750 {
    transition: all 0.75s ease;
  }

  &.speed-1000 {
    transition: all 1s ease;
  }

  &.speed-1500 {
    transition: all 1.5s ease;
  }

  &.animate-fade-in {
    opacity: 0;

    &.animated {
      opacity: 1;
    }
  }

  &.animate-fade-up {
    opacity: 0;
    transform: translateY(50px);
    

    &.animated {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &.animate-fade-in-from-left {
    opacity: 0;
    transform: translateX(-150px);
    

    &.animated {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  &.animate-fade-in-from-right{
    opacity: 0;
    transform: translateX(150px);
    

    &.animated {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}