.hero-slider {
	.hero-slide-image-container {
		widht: 100%;
		height: 50vh;
		position: relative;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.slick-arrow {
		z-index: 5;
	}

	.slick-prev {
		left: 20px;
	}

	.slick-next {
		right: 20px;
	}
}